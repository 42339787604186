import type { ISponsorBanner } from '../../molecules/SponsorBanner'
import type { IPictureElementList } from '../../atoms/PictureElementList'

import { useContext } from 'react'
import slugify from 'slugify'
import { HeaderTitleBlock } from '../../molecules/HeaderTitleBlock'
import { Carousel } from '../../molecules/Carousel'
import { PictureElementList } from '../../atoms/PictureElementList'
import { CarouselArticleItem } from '../../atoms/CarouselArticleItem'
import { ARTICLE_ICON_VIDEO } from '../../../constants/components'
import { SponsorBanner } from '../../molecules/SponsorBanner'
import { mixins, theme } from '../../../styles'
import { TagCommanderContext } from '../../../contexts/tagcommander'
import { ApiResponseContext } from '../../../contexts/ApiResponseContext'
import { useViewport } from '../../../hook/useViewport'

export interface ITopicHeader {
  description: string
  currentPage?: number
  elementList: Array<{
    hasMultiLive: boolean
    icon: string
    link: string
    pictures: IPictureElementList
    title: string
  }>
  pictures?: IPictureElementList
  sponsor?: ISponsorBanner
  title: string
  wordings: {
    carouselTitle: string
  }
}

const getTypeLabel = (type) => {
  if (type === 'category') return 'categorie'
  if (type === 'edito') return 'topic'
  if (type === 'offer') return 'offre'
  return null
}

export function TopicHeader({
  description,
  currentPage,
  elementList,
  pictures,
  sponsor,
  title,
  wordings,
}: ITopicHeader): JSX.Element {
  const { hit } = useContext(TagCommanderContext)
  const { subtype: pageType } = useContext(ApiResponseContext)
  const { isMobile } = useViewport()

  const handleOnClick = () => {
    const typeLabel = getTypeLabel(pageType)
    if (typeLabel) {
      hit(
        {
          screen_clickableElementName: `bloc_carrousel_${typeLabel}_${slugify(title, {
            lower: true,
          })}`,
        },
        { isClickEvent: true },
      )
    }
  }
  return (
    <>
      <div className="TopicHeader">
        {pictures && (
          <div className="TopicHeader__Image">
            <PictureElementList
              {...pictures}
              lazyload={false}
              importance="high"
              fetchPriority="high"
              withPreload
            />
          </div>
        )}
        <HeaderTitleBlock title={title} subtitle={description} currentPage={currentPage} />
        {sponsor && <SponsorBanner {...sponsor} withPreload theme="dark" />}
        {elementList.length > 0 && (
          <div className="TopicHeader__Container">
            {wordings?.carouselTitle && (
              <span className="TopicHeader__Container__Title">{wordings.carouselTitle}</span>
            )}
            <div className="TopicHeader__Container__Carousel">
              <Carousel pagination="mobile">
                {elementList.map((element, index) => (
                  <CarouselArticleItem
                    key={index}
                    index={index}
                    title={element?.title}
                    link={element.link}
                    pictures={element.pictures}
                    isVideo={element?.icon === ARTICLE_ICON_VIDEO}
                    isCard
                    isCarousel
                    hasMultiLive={element.hasMultiLive}
                    onClick={handleOnClick}
                    picturePriority={index === 0 || !isMobile ? 'high' : null}
                    pictureImportance={index === 0 || !isMobile ? 'high' : null}
                  />
                ))}
              </Carousel>
            </div>
          </div>
        )}
      </div>
      <style jsx>{`
        .TopicHeader {
          background-color: ${theme.cssVars.deepBlue};
        }

        .TopicHeader__Image {
          position: relative;
          height: 375px;
        }

        .TopicHeader__Image :global(picture) {
          width: 100%;
          height: 100%;
        }

        .TopicHeader__Image :global(img) {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        .TopicHeader :global(.HeaderTitleBlock) {
          padding: ${sponsor ? '25px 25px 0' : '25px'};
        }

        .TopicHeader :global(.SponsorBanner) {
          margin-bottom: 50px;
        }

        @media ${mixins.mediaQuery.tablet} {
          .TopicHeader__Image {
            height: 460px;
            max-width: 2500px;
          }

          .TopicHeader :global(.SponsorBanner) {
            margin-bottom: 70px;
          }

          .TopicHeader__Container__Carousel :global(.swiper-wrapper > *) {
            width: 360px;
          }

          .TopicHeader__Container__Carousel :global(.swiper-wrapper > *),
          .TopicHeader__Container__Carousel :global(.swiper-wrapper .ArticleCard__Content) {
            height: 450px;
          }
        }

        .TopicHeader__Container {
          background-color: ${theme.cssVars.deepBlue};
          display: block;
          width: 100%;
        }

        .TopicHeader__Container__Title {
          color: ${theme.cssVars.white};
          display: block;
          font-family: ${theme.cssVars.overpass};
          font-size: 14px;
          text-align: center;
          font-weight: 800;
          margin: auto;
          padding: 1em 0 20px;
        }

        .TopicHeader__Container__Carousel {
          position: relative;
          margin: 0 30px 0;
          padding-bottom: 50px;
        }
      `}</style>
    </>
  )
}
